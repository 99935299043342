<template>
	<div class="flexPage darkPage">
		<div class="flexContent" id="otcBox">
			<van-tabs type="card" @change="onchangeTab" v-model="typeName">
				<div class="menuIcon">
					<!-- <span @click="rightShow = true"><i class="iconfont iconfilter"></i></span> -->
					<span @click="popMenu.show = true">
						<icons type="ios" name="more" />
					</span>
				</div>
				<van-tab :title="$t('trade.buyIn')" name="SELL">
					<van-list v-model:loading="loading" :offset="10" :immediate-check="false" :finished="finished"
						finished-text="没有更多了" loading-text="加载中..." @load="onLoad">
						<div class="buyBox" v-for="item in data">
							<div class="BUY">
								<div class="buyType">
									<img src="../../assets/img/avatar.png" alt=""
										style="width: 10.1781vw;height: 10.1781vw;margin-right: 2.5445vw;">
									<div>
										<div style="display: flex;align-items: center;">{{ item.memberName }}<img
												src="../../assets/img/icon_zhifubao.png" alt=""
												style="width: 6.3613vw;height: 6.3613vw;margin-left: 2.5445vw;"
												v-if="item.payMode.split(',').includes('支付宝')">
											<img src="../../assets/img/icon_wechat.png" alt=""
												style="width: 6.3613vw;height: 6.3613vw;margin-left: 2.5445vw;"
												v-if="item.payMode.split(',').includes('微信')">
											<img src="../../assets/img/icon_yinlian.png" alt=""
												style="width: 6.3613vw;height: 6.3613vw;margin-left: 2.5445vw;"
												v-if="item.payMode.split(',').includes('银联')">
											<img src="../../assets/img/icon_guoji.png" alt=""
												style="width: 6.3613vw;height: 6.3613vw;margin-left: 2.5445vw;"
												v-if="item.payMode.split(',').includes('SWIFT')">
										</div>
										<div style="font-size: 3.0534vw;color: #aaa">成交量: {{ item.transactions }}</div>
									</div>
								</div>
								<div>
									<div style="color: #3861FB;font-size: 4.5802vw;">￥{{ item.price }}</div>
									<div style="font-size: 3.0534vw;">单价/{{ item.unit }}</div>
								</div>
							</div>
							<div
								style="display: flex;align-items: center;justify-content: space-between;margin-top: 2.5445vw;">
								<div>
									<div>数量 {{ item.remainAmount }}</div>
									<div>限额 {{ item.minLimit }}~{{ item.maxLimit }} CNY</div>
								</div>
								<div class="buyIn" @click="buyInS(item.advertiseId)">买入</div>
							</div>
						</div>
					</van-list>
				</van-tab>
				<van-tab :title="$t('trade.sellOut')" name="BUY">
					<van-list v-model:loading="loadingSell" :offset="10" :immediate-check="false"
						:finished="finishedSell" finished-text="没有更多了" loading-text="加载中..." @load="onLoadSell">
						<div class="buyBox" v-for="item in dataSell">
							<div class="BUY">
								<div class="buyType">
									<img src="../../assets/img/avatar.png" alt=""
										style="width: 10.1781vw;height: 10.1781vw;margin-right: 2.5445vw;">
									<div>
										<div style="display: flex;align-items: center;">{{ item.memberName }}<img
												src="../../assets/img/icon_zhifubao.png" alt=""
												style="width: 6.3613vw;height: 6.3613vw;margin-left: 2.5445vw;"
												v-if="item.payMode.split(',').includes('支付宝')">
											<img src="../../assets/img/icon_wechat.png" alt=""
												style="width: 6.3613vw;height: 6.3613vw;margin-left: 2.5445vw;"
												v-if="item.payMode.split(',').includes('微信')">
											<img src="../../assets/img/icon_yinlian.png" alt=""
												style="width: 6.3613vw;height: 6.3613vw;margin-left: 2.5445vw;"
												v-if="item.payMode.split(',').includes('银联')">
											<img src="../../assets/img/icon_guoji.png" alt=""
												style="width: 6.3613vw;height: 6.3613vw;margin-left: 2.5445vw;"
												v-if="item.payMode.split(',').includes('SWIFT')">
										</div>
										<div style="font-size: 3.0534vw;color: #aaa">成交量: {{ item.transactions }}</div>
									</div>
								</div>
								<div>
									<div style="color: #3861FB;font-size: 4.5802vw;">￥{{ item.price }}</div>
									<div style="font-size: 3.0534vw;">单价/{{ item.unit }}</div>
								</div>
							</div>
							<div
								style="display: flex;align-items: center;justify-content: space-between;margin-top: 2.5445vw;">
								<div>
									<div>数量 {{ item.remainAmount }}</div>
									<div>限额 {{ item.minLimit }}~{{ item.maxLimit }} CNY</div>
								</div>
								<div class="buyIn" style=" background: rgba(22, 199, 132, 1)"
									@click="SellOut(item.advertiseId)">出售</div>
							</div>
						</div>
					</van-list>
				</van-tab>
			</van-tabs>
		</div>
		<!-- 下拉弹出框 -->
		<van-action-sheet v-model="popMenu.show" :actions="actions" @select="onMenuSelect" class="downToUp" />
		<downbar />
	</div>
</template>
<script>
import downbar from "@/views/modules/downbar.vue";
import headInfo from '@/assets/img/head.jpg'
import SellOut from "./sellOut.vue";
export default {
	data() {
		return {
			typeName: "SELL",
			typeActive: 0,
			tabs: ['USDT', 'ETC'],
			symbol: '',
			pageNo: 1,
			pageSize: 10,
			pageNoSell: 1,
			pageSizeSell: 10,
			total: 0,
			totalSell: 0,
			data: [],
			dataSell: [],
			loading: false,
			finished: false,
			loadingSell: false,
			finishedSell: false,
			buyType: 1,
			rightShow: false,
			popMenu: {
				show: false,
			},
			payType: '', // 默认选中第一个
			coinType: 'CNY',
			coinList: [
				{ name: 'CNY', title: this.$t('otc.index.rmb') },
				{ name: 'USD', title: this.$t('otc.index.dollar') }]
		}
	},
	components: { downbar },
	watch: {
		"symbol": function (cur, old) {
			if (old && cur != old) {
				this.refresh();
			}
		},
		"typeName": function (cur, old) {
			if (old && cur != old) {
				this.refresh();
			}
		}
	},
	computed: {
		isLogin() {
			return this.$store.state.isLogin;
		},
		actions() {
			let result = [];
			result = [
				{ name: 'CNY', value: "cny" },
				{ name: 'USD', value: "usd" },
				{ name: '发布出售广告', value: "otcAddAd" },
				{ name: '发布购买广告', value: "otcAddAdBuy" },
				{ name: '我的广告', value: "advertlist" },
				{ name: '订单管理', value: "toBePos" },
			]
			// let merchant = this.$mts.getUserValue('isMerchant');
			// if(merchant == 'Y'){
			// 	// result = [
			//     //     {name: this.$t('otc.popup.playAd'),value:"otcAddAd"},
			//     //     {name: this.$t('otc.popup.myAd'),value:"advertlist"},
			//     //     {name: this.$t('otc.popup.setpayInfo'),value:"payaccount"},
			//     //     {name: this.$t('otc.popup.seeHistory'),value:"otcorders"}
			// 	// ]
			// }else{
			// 	result = [
			// 		{name: this.$t('otc.popup.setpayInfo'),value:"payaccount"},
			// 		{name: this.$t('otc.popup.apply'),value:"toBePos"},
			//         {name: this.$t('otc.popup.seeHistory'),value:"otcorders"}
			// 	]
			// }
			return result;
		},
		list() {
			return this.$store.state.app.vanList;
		},
		finished() {
			return this.$store.state.app.finished;
		},

		//获取所有的货币
		coin() {
			let that = this;
			let actions = [];
			let map = that.$store.state.coin;
			for (let coin in map) {
				let m = map[coin]
				if (m && m.isOtc == 'Y') {
					actions.push(coin)
				}
			}
			that.symbol = actions[0]
			return actions;
		},
	},
	mounted() {
		this.$mts.rates();
		//货币配置
		this.$mts.configure();
		//初始页面
		this.pageInit();
	},
	methods: {
		onchangeTab(name, title) {
			this.loading = false
			this.loadingSell = false
			this.pageNo = 1
			this.pageNoSell = 1
			if (name == 'BUY') {
				this.querySell()
				this.data = []
			} else {
				this.query()
				this.dataSell = []
			}
		},
		pageInit() {
			this.refresh();
		},
		buyInS(id) {
			this.$router.push({
				path: "/buyIn",
				query: {
					ids: id
				}
			})
		},
		SellOut(id) {
			this.$router.push({
				path: "/SellOut",
				query: {
					ids: id
				}
			})
		},
		onChooseTab(index, item) {
			this.typeActive = index;
			this.symbol = item;
		},
		onLoad() {
			// 异步更新数据
			setTimeout(() => {
				if (this.data.length >= this.total) {
					this.finished = true
					return
				}
				this.pageNo++
				this.query()
			}, 1000)
		},
		onLoadSell() {
			// 异步更新数据
			setTimeout(() => {
				if (this.dataSell.length >= this.totalSell) {
					this.finished = true
					return
				}
				this.pageNo++
				this.query()
			}, 1000)
		},
		//划转  订单记录  收款方式  资金密码
		onMenuSelect(item) {
			if (item.value == 'otcAddAd') {
				this.$http
					.get(this.host + '/uc/approve/certified/business/status')
					.then((res) => {
						if (res.body.data.certifiedBusinessStatus == 2) {
							this.$http.post(this.host + '/uc/approve/security/setting').then(params => {
								if (params.body.data.fundsVerified != 1) {
									this.$toast('您还没有设置资金密码 不能发布出售广告')
								} else if (params.body.data.realVerified != 1) {
									this.$toast('您还没有实名认证 不能发布出售广告')
								} else {
									this.$router.push({ name: "otcAddAd" })
								}
							})
						} else {
							this.$toast('您还没有成为商家 不能发布出售广告')
						}
					})
			} else if (item.value == 'otcAddAdBuy') {
				this.$http
					.get(this.host + '/uc/approve/certified/business/status')
					.then((res) => {
						if (res.body.data.certifiedBusinessStatus == 2) {
							this.$http.post(this.host + '/uc/approve/security/setting').then(params => {
								if (params.body.data.fundsVerified != 1) {
									this.$toast('您还没有设置资金密码 不能发布购买广告')
								} else if (params.body.data.realVerified != 1) {
									this.$toast('您还没有实名认证 不能发布购买广告')
								} else {
									this.$router.push({ name: "otcAddAdBuy" })
								}
							})
						} else {
							this.$toast('您还没有成为商家 不能发布购买广告')
						}
					})

			} else if (item.value == 'advertlist') {
				this.$router.push({ name: 'advertlist' })
			} else if (item.value == 'toBePos') {
				this.$router.push({ name: 'toBePos' })
			} else if (item.value == 'cny' && this.typeName == 'BUY') {
				this.coinType = 'CNY'
				this.querySell()
				this.popMenu.show = false
			} else if (item.value == 'cny' && this.typeName == 'SELL') {
				this.coinType = 'CNY'
				this.query()
				this.popMenu.show = false
			} else if (item.value == 'usd' && this.typeName == 'BUY') {
				this.coinType = 'USD'
				this.querySell()
				this.popMenu.show = false
			} else if (item.value == 'usd' && this.typeName == 'SELL') {
				this.coinType = 'USD'
				this.query()
				this.popMenu.show = false
			}
		},
		refresh() {
			this.pageNum = 0;
			this.$mts.initPage();
			this.$store.state.app.loading = true;
			this.query()
		},
		query() {
			this.loading = false
			let params = {
				pageNo: this.pageNo,
				pageSize: this.pageSize,
				unit: 'usdt',
				localCurrency: this.coinType,
				advertiseType: 1,
			}
			var that = this;
			that.$http.post(this.host + '/otc/advertise/page-by-unit', params).then((res) => {
				if (this.data) {
					this.data.push(...res.data.data.context)
				} else {
					this.data = res.data.data.context
				}
				this.total = res.data.data.totalElement
			})
		},
		querySell() {
			this.loadingSell = false
			let params = {
				pageNo: this.pageNoSell,
				pageSize: this.pageSizeSell,
				unit: 'usdt',
				localCurrency: this.coinType,
				advertiseType: 0,
			}
			var that = this;
			that.$http.post(this.host + '/otc/advertise/page-by-unit', params).then((res) => {
				if (this.dataSell) {
					this.dataSell.push(...res.data.data.context)
				} else {
					this.dataSell = res.data.data.context
				}
				this.totalSell = res.data.data.totalElement
			})
		}
	}
}
</script>
<style scoped>
::v-deep .van-tab--active {
	background-color: #3861FB !important;

}

::v-deep .van-tab--active .van-tab__text {
	color: #fff !important
}

::v-deep .van-tabs__nav--card {
	width: 38.1679vw !important;
	border: .2545vw solid #3861FB;
	border-right: .2545vw solid #3861FB !important;
	border-radius: 1.0178vw;
}

::v-deep .van-tab {
	border-right: .2545vw solid transparent !important;
}

::v-deep .van-tab__text {
	color: #3861FB !important
}

::v-deep .van-tabs__wrap {
	margin-left: 27.9898vw;
}

.buyIn {
	width: 17.8117vw;
	height: 8.1425vw;
	border-radius: 1.0178vw;
	background-color: #EA3943;
	font-family: SF Pro;
	font-size: 4.0712vw;
	font-weight: 510;
	line-height: 8.1425vw;
	text-align: center;
	color: #fff
}

.BUY {
	display: flex;
	justify-content: space-between
}

.buyType {
	display: flex;
	align-items: center;

}

.buyBox {
	width: 95%;
	border-bottom: .2545vw solid #ccc;
	margin: 0 auto;
	margin-top: 3.8168vw;
	padding-bottom: 3.8168vw
}
</style>
