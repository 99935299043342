<template>
    <div>
        <van-nav-bar title="出售" left-arrow :border="false" @click-left="onClickLeft" />
        <div class="header">
            <div class="orderBox">
                <div style="display: flex;align-items: center">
                    <img src="../../assets/img/avatar.png" alt=""
                        style="width: 10.1781vw;height: 10.1781vw;margin-right: 2.5445vw">
                    <div>
                        <div class="orderText">{{ info.username }}</div>
                        <div class="orderText2">成交量: {{ info.transactions }}</div>
                    </div>
                </div>
                <div>
                    <div class="orderText1">{{ info.price }}CNY</div>
                    <div class="orderText2" style="margin-top: 1.0178vw;text-align: center;">单价</div>
                </div>
            </div>
            <div class="orderBox" style="margin-top: 2.5445vw">
                <div style="display: flex;align-items: center">
                    <div>
                        <div class="orderText">剩余数量 {{ info.number }}</div>
                        <div class="orderText">{{ info.minLimit }}~{{ info.maxLimit }}CNY</div>
                    </div>
                </div>
                <div>
                    <div class="orderText1"><img src="../../assets/img/icon_zhifubao.png" alt=""
                            style="width: 4.3613vw;height: 4.3613vw;margin-left: 2.5445vw;"
                            v-if="info.payMode.split(',').includes('支付宝')">
                        <img src="../../assets/img/icon_wechat.png" alt=""
                            style="width: 4.3613vw;height: 4.3613vw;margin-left: 2.5445vw;"
                            v-if="info.payMode.split(',').includes('微信')">
                        <img src="../../assets/img/icon_yinlian.png" alt=""
                            style="width: 4.3613vw;height: 4.3613vw;margin-left: 2.5445vw;"
                            v-if="info.payMode.split(',').includes('银联')">
                        <img src="../../assets/img/icon_guoji.png" alt=""
                            style="width: 4.3613vw;height: 4.3613vw;margin-left: 2.5445vw;"
                            v-if="info.payMode.split(',').includes('SWIFT')">
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div style="margin-left: 17px;margin-top: 20px;">交易数量</div>
            <van-field v-model="amount" placeholder="根据金额自动计算数量" :disabled="true">
            </van-field>
            <div style="margin-left: 17px;margin-top: 20px;">交易金额</div>
            <van-field v-model="mount" placeholder="请输入您想要出售的金额" @input="input">
            </van-field>
            <div class="note">*订单总价需在广告发布价格之内</div>
            <div class="sure" @click="buy">出售</div>
        </div>
        <van-popup v-model="show" position="bottom" :style="{ height: '302px' }">
            <div class="title">下单确认</div>
            <div class="content">
                <div class="contentText">
                    <span>出售价格</span>
                    <span>{{ info.price }} CNY</span>
                </div>
                <div class="contentText">
                    <span>出售数量</span>
                    <span>{{ amount }} USDT</span>
                </div>
                <div class="contentText">
                    <span>出售总额</span>
                    <span>{{ mount }} CNY</span>
                </div>
            </div>
            <div class="noteD">提醒: 请确认价格再下单，下单后此交易的资金将托管锁定，请放心购买</div>
            <div class="btn" @click="surePay">确定</div>
        </van-popup>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            info: {},
            mount: '',
            amount: ''
        }
    },
    created() {
        this.getInfo()
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
        //获取详情
        async getInfo() {
            const res = await this.$http.post(this.host + '/otc/order/pre', { id: this.$route.query.ids })
            this.info = res.data.data
        },
        input(val) {
            this.amount = Number(String(val / this.info.price).replace(/^(.*\..{4}).*$/, "$1"))

        },
        buy() {
            if (this.mount < this.info.minLimit || this.mount > this.info.maxLimit) {
                this.$toast(`金额应该在${this.info.minLimit}-${this.info.maxLimit}之间`)
            } else {
                this.show = true
            }
        },
        async surePay() {
            const data = {
                price: Number(this.info.price),
                money: Number(this.mount),
                amount: Number(this.amount),
                remark: this.amount,
                coinId: this.info.otcCoinId,
                id: this.$route.query.ids
            }
            const res = await this.$http.post(this.host + '/otc/order/sell', data)
            if (res.data.code == 500) {
                this.$toast(res.data.message)
            } else {
                this.$toast(res.data.message)
                this.show = false
                this.$router.push("/toBePos")
            }
        }
    }
}
</script>

<style scoped>
.btn {
    width: 100%;
    height: 10.1781vw;
    background-color: #3861FB;
    margin-top: 13.2316vw;
    color: #fff;
    text-align: center;
    line-height: 10.1781vw;
    font-size: 4.0712vw;
    letter-spacing: .5089vw;
}

.noteD {
    width: 91.6031vw;
    margin: 0 auto;
    letter-spacing: .2545vw;
    margin-top: 2.5445vw;
    color: #333
}

::v-deep .van-popup {
    background-color: rgb(238, 238, 238);
}

.contentText span:first-child {
    font-size: 3.8168vw;
}

.contentText span:last-child {
    font-size: 3.5623vw;
    color: red
}

.contentText {
    width: 81.4249vw;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 2.5445vw;
}

.content {
    width: 91.6031vw;
    height: 27.9898vw;
    background-color: #fff;
    border: .2545vw solid #ccc;
    margin: 0 auto;
    border-radius: 1.2723vw;
    margin-top: 2.0356vw;
}

.title {
    font-size: 4.0712vw;
    text-align: center;
    margin-top: 3.8168vw;
}

.sure {
    width: 86.514vw;
    height: 10.1781vw;
    background-color: #3861FB;
    margin: 0 auto;
    margin-top: 6.3613vw;
    border-radius: 1.5267vw;
    color: #fff;
    text-align: center;
    line-height: 10.1781vw;
}

.note {
    color: red;
    font-size: 3.0534vw;
    margin-left: 4.3257vw;
    margin-top: -1.2723vw;
    position: relative;
    z-index: 100;
    letter-spacing: .5089vw;
}

::v-deep .van-field__control {
    height: 7.6336vw;
    border: .2545vw solid #3861FB;
}

::v-deep .van-cell {
    height: 12.7226vw !important;
}

::v-deep .van-cell__title {
    color: #3861FB !important;
}

::v-deep .van-cell__value {
    color: #3861FB !important;
}

.main {
    width: 95%;
    height: 68.3444vw;
    background-color: #FFF;
    border-radius: 0.25vw;
    margin: 0 auto;
    margin-top: -1.25vw;
    overflow: hidden;
}

.orderBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.coin {
    width: 5vw;
    height: 1.5626vw;
    font-size: 0.75vw;
    color: #fff;
    text-align: center;
    line-height: 1.75vw;
    background: rgba(22, 199, 132, 1)
}

.orderText {
    font-size: 13px;
    color: #fff
}

.orderText1 {
    font-size: 15px;
    color: #3861FB;
    font-weight: bold;
}

.orderText2 {
    font-size: 11.9999px;
    color: rgba(255, 255, 255, 0.6);
    letter-spacing: 2px;
}

.header {
    width: 100%;
    height: 149.9998px;
    background-color: rgb(43, 43, 43);
    padding: 0 15px;
    padding-top: 20.0002px;

}

.van-nav-bar {
    background-color: rgb(43, 43, 43) !important;
}

::v-deep .van-icon {
    color: #FFF;
}

::v-deep .van-nav-bar__title {
    color: #fff !important;
    font-family: SF Pro;
    font-size: 15.9998px;
    font-weight: 510;
}
</style>